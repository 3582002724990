<template>
  <div>
    <div>
      <button class="no-bg-btn-left" @click="openEdit">
        Plan expiry date: {{ user.plan_expiry_date && moment(user.plan_expiry_date).format("LL") }}
      </button>
      <div @click="openEdit">
        Current plan: {{ (user.plan_level && user.plan_level.name) ||"-" }}
      </div>
      <div>
        Payment Info: {{ user.subscription_id }}
      </div>
    </div>

    <b-modal :id="`update-subscriptions-${this.user._id}`" title="Update Subscriptions" hide-footer>
      <FormulateForm v-model="formValues" @submit="updateUserLevel" :schema="schema">
        <div class="row">
          <div class="col-md-6 pt-2">
            <label class="typo__label" for="ajax">Level</label>
            <multiselect v-model="selectedLevel" label="name" track-by="_id" placeholder="Type to search"
              open-direction="bottom" :multiple="false" :searchable="true" :options="subscriptionLevels"
              :loading="fetchingLevel" :internal-search="true" :clear-on-select="false" :close-on-select="true"
              :options-limit="100" :limit="3" :max-height="600" :show-no-results="true" :hide-selected="true">
            </multiselect>
          </div>
        </div>
        <div class="mt-3" v-if="selectedLevel">
          <table class="col-4">
            <tr>
              <td>
                Name
              </td>
              <td>
                {{selectedLevel.name}}
              </td>
            </tr>
            <tr v-for="key,value in selectedLevel.features" :key="key">
              <td>
                {{value}}
              </td>
              <td>
                {{key}}
              </td>
            </tr>

          </table>
        </div>
        <div style="text-align: end" class="mt-4">
          <FormulateInput type="submit" :disabled="submitting" :label="submitting ? 'Updating...' : 'Update'" />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: {
    user: {
      type: Object
    },
    subscriptionLevels: {
      type: Array
    }
  },
  mounted() {
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      schema: [
        {
          index: 0,
          type: "date",
          name: "plan_expiry_date",
          label: "Expiry Date",
          "validation-messages": { required: "Expiry date is required" },
          validation: "required",
        },
      ],
      formValues: {

      },
      selectedLevel: undefined,
      file: undefined,

      submitting: false,
      fetchingLevel: false,


    };
  },

  methods: {
    openEdit() {
      this.formValues['plan_expiry_date'] = this.user.plan_expiry_date && this.moment(this.user.plan_expiry_date).format("YYYY-MM-DD")
      this.selectedLevel = this.user.plan_level;
      this.$bvModal.show(`update-subscriptions-${this.user._id}`);
    },
    hideEdit() {
      this.$bvModal.hide(`update-subscriptions-${this.user._id}`);
    },
    updateUserLevel(values) {
      console.log(values)
      const data = {
        plan_level: this.selectedLevel._id,
        ...values,
      }
      const api = {
        url: "admin/user/setUserPlan/",
        id: this.user._id,
        method: "PUT",
        data,
      }
      this.submitting = true;
      this.generateAPI(api)
        .then((res) => {
          this.user.plan_level = res.data.user.plan_level
          this.user.plan_expiry_date = res.data.user.plan_expiry_date
          this.hideEdit();
          this.$bvToast.toast("Updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.submitting = false;
        });
    },



  },
};
</script>
