<script>
import Layout from "@/views/layouts/main";
import { users } from "@/config/api/users";
import PageHeader from "@/components/page-header";
import UserModal from "@/components/users/user-modal";
import _ from "lodash";
import UpdateSub from "@/components/users/update-sub";
import { levels } from "@/config/api/levels";

export default {
  components: { Layout, UserModal, PageHeader, UpdateSub },
  data() {
    return {
      subscriptionLevels: [],
      title: "Users",
      userData: [],
      selectedUser: null,
      userRoles: [],
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      activeTab: null,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "name", sortable: false },
        { key: "email", sortable: false },
        { key: "expert", sortable: false },
        { key: "phone_number", sortable: false },
        { key: "subscriptions", sortable: false },
        { key: "createdAt", sortable: false },
      ],
      items: [
        {
          text: "Users",
          active: true,
        },
        {
          text: "",
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },

    computedFields() {
      if (this.activeTab == 1) {
        return this.fields.filter((field) => !field.show);
      }
      return this.fields;
    },
  },
  watch: {
    filter() {
      this.searchForUser();
    },
  },
  mounted() { },
  created() {
    this.findCertificate()
  },
  methods: {
    findCertificate() {
      const api = levels.getAll;
      this.generateAPI(api)
        .then((res) => {
          this.subscriptionLevels = res.data.plans;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchForUser: _.throttle(function () {
      this.currentPage = 1;
      this.loadInfo();
    }, 2000),
    tabChanged(currentTabs) {
      this.currentTabs = currentTabs;
      this.loadInfo();
    },
    onFiltered() {
      // this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    updatePage(page) {
      this.currentPage = page;
      this.loadInfo();
    },
    updatePageLimit(val) {
      this.perPage = val;
      this.loadInfo();
    },
    loadInfo() {
      this.loading = true;
      const api = users.getAll;
      let params = {
        page: this.currentPage,
        search: this.filter,
        limit: this.perPage,
        isCompany: false
      };

      switch (this.currentTabs) {
        case 1:
          params.deletedAt = false;
          break;
        case 2:
          params.deletedAt = true;
          break;
      }
      api.params = params;
      this.generateAPI(api)
        .then((res) => {
          this.userData = res.data.users.docs;

          this.totalRows = res.data.users.totalDocs;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRowClicked(item) {
      this.$router.push({
        path: "/user-info-v2",
        query: { id: item._id },
      });
    },
    editUser(data) {
      this.selectedUser = data;
      this.showActionModal();
    },
    showActionModal() {
      this.$bvModal.show("action-User");
    },
    hideActionModal() {
      this.$bvModal.hide("action-User");
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-button id="addUsers" @click="showActionModal" pill variant="primary">Add Users</b-button>
            <!-- All Users -->
            <b-tabs nav-class="nav-tabs-custom" active-nav-item-class="font-weight-bold"
              active-tab-class="font-weight-bold" content-class="mt-3" v-on:input="tabChanged">
              <b-tab title-link-class="p-3" active>
                <template v-slot:title>
                  <a class="font-weight-bold active">All Users</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions" @change="updatePageLimit">
                        </b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table tbody-tr-class="rowClass" class="table-centered" hover :items="userData" :fields="fields"
                    responsive="sm" :busy="loading" @row-clicked="onRowClicked">
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69"></pulse-loader>
                      </div>
                    </template>
                    <template #cell(name)="data">
                      {{  data.item.first_name }}<br />

                      <br />
                      <b-badge pill variant="danger" v-if="data.item.deletedAt">Deleted</b-badge>
                    </template>
                    <template #cell(email)="row">
                      {{ row.item.email }}
                      <span v-if="row.item.is_email_verified" style="color: green">
                        <i class="ri-checkbox-circle-line"></i>
                      </span>
                    </template>
                    <template #cell(expert)="row">
                      {{ row.item.is_expert ? "Yes" : "No" }}

                    </template>
                    <template #cell(subscriptions)="row">
                      <UpdateSub :user="row.item" :subscriptionLevels="subscriptionLevels"></UpdateSub>
                    </template>
                    <template #cell(phone_number)="row">
                      {{ row.item.country_code }}-{{ row.item.phone_number }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("LL") }}
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination :value="currentPage" :total-rows="rows" :per-page="perPage" @change="updatePage">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <!-- Active Users -->
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Active Users</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table tbody-tr-class="rowClass" class="table-centered" hover :items="userData"
                    :fields="computedFields" responsive="sm" :busy="loading" :per-page="perPage"
                    @row-clicked="onRowClicked">
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69"></pulse-loader>
                      </div>
                    </template>
                    <template #cell(name)="data">
                      {{  data.item.first_name }}<br />
                      <b-badge pill variant="primary" v-if="data.item.companies_owned">Company Acccount</b-badge>
                    </template>
                    <template #cell(email)="row">
                      {{ row.item.email }}
                      <span v-if="row.item.is_email_verified" style="color: green">
                        <i class="ri-checkbox-circle-line"></i>
                      </span>
                    </template>
                    <template #cell(phone_number)="row">
                      {{ row.item.country_code }}-{{ row.item.phone_number }}
                    </template>

                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("l") }}
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination :value="currentPage" :total-rows="rows" :per-page="perPage" @change="updatePage">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <!-- Deleted Users -->
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Deleted Users</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table tbody-tr-class="rowClass" class="table-centered" hover :items="userData" :fields="fields"
                    responsive="sm" :busy="loading" :per-page="perPage" @row-clicked="onRowClicked">
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69"></pulse-loader>
                      </div>
                    </template>
                    <template #cell(name)="data">
                      {{  data.item.first_name }}<br />
                      <b-badge pill variant="primary" v-if="data.item.companies_owned">Company Acccount</b-badge>
                    </template>
                    <template #cell(email)="row">
                      {{ row.item.email }}
                      <span v-if="row.item.is_email_verified" style="color: green">
                        <i class="ri-checkbox-circle-line"></i>
                      </span>
                    </template>
                    <template #cell(phone_number)="row">
                      {{ row.item.country_code }}-{{ row.item.phone_number }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("l") }}
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination :value="currentPage" :total-rows="rows" :per-page="perPage" @change="updatePage">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <UserModal :currentCompany="loadInfo" :selectedUser="selectedUser" v-on:resetModal="selectedUser = null"
      v-on:reloadData="loadInfo" v-on:closeModal="hideActionModal" />
  </Layout>
</template>

<style>
#addUsers {
  float: right;
  margin-top: 10px;
}
</style>
