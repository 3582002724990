export const levels = {
  getAll: {
    url: "plan-level/",
    method: "GET",
    id: null,
    params: null,
  },
  delete: {
    url: "plan-level/",
    method: "DELETE",
  },
  create: {
    url: "plan-level/",
    method: "POST",
    data: null,
  },
  update: {
    url: "plan-level/",
    method: "PUT",
    id: null,
    data: null,
  },
};
