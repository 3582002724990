export const users = {
  getAll: {
    url: "admin/user/getAll",
    method: "GET",
    id: null,
    params: null,
  },
  getById: {
    url: "admin/user/get/",
    method: "GET",
    id: null,
    params: null,
  },
  create: {
    url: "admin/createNewUser",
    method: "POST",
    data: null,
  },
  update: {
    url: "user/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  delete: {
    url: "user/delete/",
    method: "DELETE",
    id: null,
    data: null,
  },
  getCompanies: {
    url: "user/getCompanies/",
    method: "GET",
    id: null,
    data: null,
  },
  setPlanLevel: {
    url: "/admin/user/setUserPlan/",
    method: "PUT",
    id: null,
    data: null,
  },
  changeProfilePic: {
    url: "admin/user/profile_picture/",
    method: "PUT",
    id: null,
    data: null,
  },
  deleteProfilePic: {
    url: "admin/user/profile_picture/",
    method: "DELETE",
    id: null,
  },
  changePassword: {
    url: "admin/changePassword",
    method: "POST",
    data: null,
  },
  admin_profession: {
    url: "admin/profession/get/",
    method: "GET",
    data: null,
  },
  sub_profession: {
    url: "subprofession/get",
    method: "GET",
    data: null,
  },
  forgotPassword: {
    url: "admin/forgotPassword",
    method: "POST",
    data: null,
  },
  editPassword: {
    url: "admin/changeUserPassword",
    method: "POST",
    data: null,
  },
  nationality: {
    getAll: {
      url: "admin/country/getAll",
      method: "GET",
      id: null,
      params: null,
    },
  },
  timeline: {
    url: "user/timeline/",
    method: "GET",
    id: null,
    params: null,
  },
  uploadDocs: {
    url: "user/admin/upload_documents",
    method: "POST",
    id: null,
    params: null,
  },
  changeActiveSocialLinks: {
    url: "user/admin/changeActiveSocialMediaAdmin/",
    method: "PUT",
    id: null,
    data: null,
  },
  getAdditionalDocs: {
    url: "user/admin/getAdditionalDocuments/",
    method: "GET",
    id: null,
    data: null,
  },
  changeUserPassword: {
    url: "admin/changeUserPassword",
    method: "POST",
    id: null,
    data: null,
  },
  becomeAnExpertFromAdmin: {
    url: "user/admin/becomeAnExpertFromAdmin",
    method: "POST",
    id: null,
    data: null,
  },
  pendingExpertRequest: {
    url: "expertrequest/pending",
    method: "GET",
  },
  expertRequest: {
    accept: {
      url: "expertrequest/accept/",
      method: "PUT",
      id: null,
    },
    decline: {
      url: "expertrequest/decline/",
      method: "PUT",
      id: null,
    },
    directUpdate: {
      url: "expertrequest/admin/makeExpert",
      method: "POST",
      data:{}
    }
  },
};
