<script>
import { users } from "@/config/api/users";
import { levels } from "@/config/api/levels";
import Multiselect from "vue-multiselect";
import { consoleSandbox } from "@sentry/utils";

// import { getFormData } from "@/utils/createForm";

/**
 * Add User Component
 */
export default {
  props: ["currentCompany", "selectedUser"],
  components: { Multiselect },
  data() {
    return {
      title: "Users",
      items: [
        {
          text: "All Users",
          to: { path: `/users` },
        },
        {
          text: "Add",
          active: true,
        },
      ],
      userId: null,
      loading: false,
      values: {},
      major_professions: [],
      sub_professions: [],
      nationalityList: [],
      selectedNationality: null,
      nationality_loading: null,
      levelList: [],
      selectedPlanLevel: null,
      // usertype: '',
      plan_list_loading: null,
      selected_major_professions: '',
      selected_sub_professions: '',
      loading_major_professions: false,
      loading_sub_professions: false,
      nationalIdFront: null,
      nationalIdBack: null,
      expertDocs: null,
      planExpiryDate: null,
      schema: [
        {
          index: 1,
          type: "text",
          name: "name",
          label: "Full Name",
          placeholder: "John Doe",
          "validation-messages": { required: "User Name is required" },
          validation: "required",
        },
        {
          index: 2,
          type: "text",
          name: "email",
          label: "Email",
          "validation-messages": { required: "User Email is required" },
          placeholder: "example@email.com",
          validation: "required|email",

        },
        {
          component: "div",
          class: "row",
          children: [

            {
              index: 31,
              type: "url",
              name: "instagram_url",
              label: "Instagram",
              class: "col-sm-4",

            },
            {
              index: 32,
              type: "url",
              name: "facebook_url",
              label: "Facebook",
              class: "col-sm-4",
            },
            {
              index: 33,
              type: "password",
              name: "password",
              label: "Password",
              class: "col-sm-4",
              "validation-messages": {
                required: "User Password is required",
              },
            },
          ],
        },
        {
          component: "div",
          class: "row",
          children: [
            {
              index: 41,
              type: "tel",
              name: "country_code",
              label: "Country Code",
              class: "col-sm-2",
              placeholder: "eg:971"
            },
            {
              index: 42,
              type: "tel",
              name: "phone_number",
              label: "Phone Number",
              class: "col-sm-10",
              placeholder: "eg:5xxxxxxxx"

            },

          ],
        }, {
          component: "div",
          class: "row",
          children: [
            {
              index: 51,
              type: "checkbox",
              name: "is_email_verified",
              label: "Verified",
              class: "col-sm-2",
            },
            {
              index: 52,
              type: "checkbox",
              name: "is_expert",
              label: "Expert",
              class: "col-sm-2",
            },
            {
              index: 53,
              type: "checkbox",
              name: "is_private",
              label: "Private",
              class: "col-sm-2",
            },
          ]
        },


      ],
    };
  },
  methods: {
    resetModal() {
      this.nationalIdFront = null;
      this.nationalIdBack = null;
      this.expertDocs = null;
      this.values = {};

      this.nationalityList = [],
        this.levelList = [],
        this.$emit("resetModal");
    },

    async submitHandler(data) {
      // console.log("This is data", data);

      if (this.selectedUser) {
        console.log("update user ")
        this.updateUser(data);
      } else {
        console.log("add user ")

        this.addUser(data);
      }
    },
    addUser(data) {
      this.loading = true;

      const api = users.create;
      api.data = {
        ...data,
        company_id: this.currentCompany._id,
        user_id: data.user_id ? data.user_id : null,
        is_email_verified: data.isVerified ? data.isVerified : false,
        is_private: data.is_private ? data.is_private : false,
        is_expert: data.isExpert ? data.isExpert : false,
        nationality: this.selectedNationality ? this.selectedNationality._id : null,
        nationalityRef: "Country",
        plan_level: this.selectedPlanLevel ? this.selectedPlanLevel._id : null,
        plan_expiry_date: this.planExpiryDate || null,
        sub_profession: this.selected_sub_professions && this.selected_sub_professions._id
      };
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("User added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("User is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("closeModal");
        });
    },
    loadingMajorProfessions() {
      this.loading_major_professions = true;
      const api = users.admin_profession;
      this.generateAPI(api).then((res) => {
        this.major_professions = res.data.professions
      }).finally(() => {
        this.loading_major_professions = false;
      })
    },
    loadingSubProfessions() {
      this.loading_sub_professions = true;
      const api = users.sub_profession;
      api.params = {
        main_profession: this.selected_major_professions._id,
        name: ""
      }
      this.generateAPI(api).then((res) => {
        this.sub_professions = res.data.professions
      }).finally(() => {

        this.loading_sub_professions = false;
      })
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    updateUser(data) {
      this.loading = true;
      if (data.nationalIdBack) {
        data.national_id_back = data.nationalIdBack[0].location;
      }
      if (data.nationalIdFront) {
        data.national_id_front = data.nationalIdFront[0].location;
      }
      if (data.expertDocs) {
        data.expert_docs = data.expertDocs[0].location;
      }
      this.$delete(data, "expertDocs");
      this.$delete(data, "nationalIdBack");
      this.$delete(data, "nationalIdFront");
      const api = users.update;
      api.id = this.selectedUser._id;


      api.data = {
        ...data,
        company_id: this.currentCompany._id,
        user_id: data.user_id ? data.user_id : null,
        is_email_verified: data.is_email_verified ? data.is_email_verified : false,
        is_private: data.is_private ? data.is_private : false,
        is_expert: data.is_expert ? data.is_expert : false,
        plan_level: this.selectedPlanLevel._id,
        plan_expiry_date: this.planExpiryDate,
        nationality: this.selectedNationality._id,
        nationalityRef: "Country",
        national_id_front: data.national_id_front,
        national_id_back: data.national_id_back,
        expert_documents: {
          profession_document: data.expert_docs,
        },
        sub_profession: this.selected_sub_professions && this.selected_sub_professions._id
      };

      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("User updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("User is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("closeModal");
        });
    },
    getNationalities() {
      this.nationality_loading = true
      const api = users.nationality.getAll;

      this.generateAPI(api).then((res) => {
        this.nationalityList = res.data.countries;
        this.nationality_loading = false
      });
    },
    getPlanLevels() {
      this.plan_list_loading = true
      const api = levels.getAll;

      api.params = {
        user_type: "user"
      };

      this.generateAPI(api).then((res) => {
        this.levelList = res.data.plans;
        this.plan_list_loading = false
      });
    },

  },
  watch: {
    selectedUser(val) {
      if (val) {
        this.values = { ...val };
        if (val.sub_profession) {
          this.selected_sub_professions = val.sub_profession
          this.selected_major_professions = { _id: val.sub_profession.main_profession }
        }
        if (val.nationality) {
          this.selectedNationality = val.nationality
        }
        if (val.plan_level) {
          this.selectedPlanLevel = val.plan_level
        }
        if (val.plan_expiry_date) {
          this.planExpiryDate = val.plan_expiry_date
        }

        this.expertDocs = [
          {
            url: val.expert_documents.profession_document,
          },
        ];

        this.nationalIdFront = [
          {
            url: val.national_id_front,
          },
        ];

        this.nationalIdBack = [
          {
            url: val.national_id_back,
          },
        ];
        this.loadingMajorProfessions()
        this.getNationalities()
        this.getPlanLevels()
      }
    },
    selected_major_professions() {
      this.loadingSubProfessions()
    },

  },

  computed: {
    usertype() {
      this.values.companies_owned != null ? "company" : "user"
    }
  },
  mounted() {

  },
  created() {
    this.loadingMajorProfessions()
    this.getNationalities()
    this.getPlanLevels()
  },
};
</script>

<template>
  <b-modal id="action-User" size="xl" @hidden="resetModal" :title="selectedUser ? 'Update User' : 'Add User'"
    hide-footer>

    <FormulateForm name="UserAction" v-model="values" :schema="schema" @submit="submitHandler">

      <div class="row" style="margin-bottom:15px; ">
        <div class="col-md-4">

          <div class="row-3">
            <label for="input-5">Nationality</label>
          </div>

          <div class="row-9">

            <b-form-select v-model="selectedNationality" class="mt-2">
              <template #first>
                <b-form-select-option :value="null" disabled>
                  <span v-if="nationality_loading">Loading Please wait </span>
                  <span v-else>-- Select Nationality --</span>

                </b-form-select-option>

              </template>
              <b-form-select-option v-for="nationality in nationalityList" :key=nationality._id :value="nationality">
                {{ nationality.name }}

              </b-form-select-option>
            </b-form-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row-3">
            <label for="input-5">Plan Level</label>
          </div>

          <div class="row-9">

            <b-form-select v-model="selectedPlanLevel" class="mt-2">
              <template #first>
                <b-form-select-option :value="null" disabled>
                  <span v-if="plan_list_loading">Loading Please wait </span>
                  <span v-else>-- Select Plan Level --</span>

                </b-form-select-option>

              </template>
              <b-form-select-option v-for="level in levelList" :key=level.id :value="level">
                {{ level.name }}
              </b-form-select-option>
            </b-form-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row-3">
            <label for="input-5">Plan Expiry Date</label>
          </div>

          <div class="row-9 mt-2">
            <b-form-datepicker id="example-datepicker" v-model="planExpiryDate" class="mb-2"></b-form-datepicker>

          </div>
        </div>
      </div>

      <div class="row" style="margin-bottom:15px; " v-if="this.selectedUser">
        <div class="col-md-4 col-sm-6">
          <FormulateInput type="image" name="expertDocs" v-model="expertDocs" label="Expert Document"
            help="Upload a Expert Document for the user (png/jpg)." />
          <!-- <button v-if="expertDocs" type="button" @click="deleteDoc('expertDocs')" class="delete-company-image">
            Delete
          </button> -->
        </div>
        <div class="col-md-4 col-sm-6">
          <FormulateInput type="image" name="nationalIdBack" v-model="nationalIdBack" label="National ID Back"
            help="Upload National ID Back (png/jpg)." />
          <!-- <button v-if="nationalIdBack" type="button" @click="deleteDoc('nationalIdBack')" class="delete-company-image">
            Delete
          </button> -->
        </div>
        <div class="col-md-4 col-sm-6">
          <FormulateInput type="image" name="nationalIdFront" v-model="nationalIdFront" label="National ID Front"
            help="Upload National ID Front (png/jpg)." />
          <!-- <button v-if="nationalIdFront" type="button" @click="deleteDoc('nationalIdFront')"
            class="delete-company-image">
            Delete
          </button> -->
        </div>
      </div>
      <multiselect v-model="selected_major_professions" id="selected_major_professions" label="name" track-by="_id"
        placeholder="Major Professions" open-direction="bottom" :options="major_professions" :multiple="false"
        :searchable="true" :loading="loading_major_professions" :internal-search="true" :clear-on-select="false"
        :close-on-select="true" :options-limit="300" :limit="10" :max-height="600" :show-no-results="true">
      </multiselect>
      <multiselect v-model="selected_sub_professions" id="selected_sub_professions" label="name" track-by="_id"
        placeholder="Sub Professions" open-direction="bottom" :options="sub_professions" :multiple="false"
        :searchable="true" :loading="loading_sub_professions" :internal-search="true" :clear-on-select="true"
        :close-on-select="true" :options-limit="300" :limit="10" :max-height="600" :show-no-results="true">
        <template slot="option" slot-scope="props">
          <div class="row col-12">
            {{capitalizeFirstLetter(props.option.name) }}
          </div>

        </template>
        <template slot="singleLabel" slot-scope="props">
          <div class="row col-12">
            {{capitalizeFirstLetter(props.option.name) }}
          </div>
        </template>
      </multiselect>

      <FormulateInput type="submit" class="mt-2 float-right">
        <span v-if="loading">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedUser"> Updating... </span>
          <span v-else> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selectedUser"> Update </span>
          <span v-else> Add </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>


<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}

.payment-duration .formulate-input-label {
  margin-top: -6px;
}

.delete-company-image {
  position: absolute;
  top: 10px;
  right: 12px;
  border: none;
  background: #fff;
  color: rgb(242 66 54);
  border: 1px solid rgb(242 66 54);
  border-radius: 4px;
}

@media (min-width: 1200px) {
  .modal-dialog {
    max-width: 1650px !important;
  }
}

@media (min-width: 992px) {
  .modal-xl {
    max-width: 1200px !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 950px !important;
  }
}
</style>